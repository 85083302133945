<template>
  <div class="container">
    <div id="carouselExampleDark" class="carousel slide" data-bs-ride="true">
      <div class="carousel-indicators" style="margin-bottom: -0.5rem">
        <!-- 按鈕1 -->
        <button
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>

        <!-- 按鈕2 -->
        <button
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>

        <!-- 按鈕3 -->
        <button
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>

        <!-- 按鈕4 -->
        <button
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide-to="3"
          aria-label="Slide 4"
        ></button>
      </div>

      <div class="carousel-inner">
        <!-- Banner 1 -->
        <div class="carousel-item active" data-bs-interval="10000">
          <div class="row">
            <div
              class="T-light col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-1 order-lg-1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <span class="T1">聯洋陪你~</span>
              <span class="T2">建立孩子的英文基礎</span>
              <span class="T3">
                Discover Phonics (Grades K~3)全套共有4冊，<br />
                透過充滿活力的歌曲、<br />
                依循漸進的自然發音讀本、豐富的多媒體，<br />
                在邊學邊玩的過程中建立自信心，<br />
                讓孩子從初級閱讀者發展為流利的閱讀者。</span
              >
              <div class="justify-content-center justify-content-lg-start">
                <a
                  href="/BookPage/PhonicPage/DiscoverPhonics"
                  target="_blank"
                  class="btn-more scrollto"
                  >了解更多 ▶
                </a>
              </div>
            </div>
            <div
              class="col-lg-6 order-1 order-lg-2 hero-img"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <img
                src="../assets/img-1/Banner/01_Banner_small_Phonics.png"
                class="img-fluid"
                alt="Banner 1"
              />
            </div>
          </div>
        </div>

        <!-- Banner 2 -->
        <div class="carousel-item" data-bs-interval="10000">
          <div class="row">
            <div
              class="T-light col-lg-6 d-flex flex-column justify-content-center pt-3 pt-lg-0 order-2 order-lg-1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <span class="T1">聯洋陪你~</span>
              <span class="T2">增強孩子的英文閱讀能力</span>
              <span class="T3">
                Connect(Grades K-6) 全套共有7冊，<br />
                透過系統化及結構化的教學方式，<br />
                活潑且實用地將英文教學連結至其他科目，<br />
                進而有效提高孩子的閱讀技能。</span
              >
              <div class="justify-content-center pb justify-content-lg-start">
                <a
                  href="/BookPage/ReadingPage/VistaConnectGK"
                  target="_blank"
                  class="btn-more scrollto pb"
                  >Grade K ▶
                </a>
                <a
                  href="/BookPage/ReadingPage/VistaConnectEnglishG1-6"
                  target="_blank"
                  class="btn-more scrollto pb"
                  >Grades 1-6 ▶
                </a>
              </div>
            </div>
            <div
              class="col-lg-6 order-2 order-lg-2 hero-img"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <img
                src="../assets/img-1/Banner/02_Banner_small_Connect.png"
                class="img-fluid animated"
                alt="Banner 2"
              />
            </div>
          </div>
        </div>

        <!-- Banner 3 -->
        <div class="carousel-item" data-bs-interval="10000">
          <div class="row">
            <div
              class="T-light col-lg-6 d-flex flex-column justify-content-center pt-3 pt-lg-0 order-3 order-lg-1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <span class="T1">聯洋陪你~</span>
              <span class="T2">強化孩子聽說讀寫</span>
              <span class="T2">全方位的英文能力</span>
              <span class="T3">
                Bridges (Grades 6~8)全套共有3冊，<br />
                旨在培養中學生的自主閱讀能力。<br />
                豐富的線上學習資源以及跨學科的內容，<br />
                為孩子的英語學習增添更多的動力。</span
              >
              <div class="justify-content-center justify-content-lg-start">
                <a
                  href="/BookPage/ReadingPage/VistaBridgesG6-8"
                  target="_blank"
                  class="btn-more scrollto"
                  >了解更多 ▶
                </a>
              </div>
            </div>
            <div
              class="col-lg-6 order-3 order-lg-2 hero-img"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <img
                src="../assets/img-1/Banner/03_Banner_small_Bridges.png"
                class="img-fluid animated"
                alt="Banner 3"
              />
            </div>
          </div>
        </div>

                <!-- Banner 4 -->
                <div class="carousel-item" data-bs-interval="10000">
          <div class="row">
            <div
              class="T-light col-lg-6 d-flex flex-column justify-content-center pt-3 pt-lg-0 order-4 order-lg-1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <span class="T1">聯洋陪你~</span>
              <span class="T2">在孩子進入大學前</span>
              <span class="T2">做最好的準備</span>
              <span class="T3">
                Engage (Grades 9~12) 全套共有4冊，針對高中生而設計。<br />
                旨在幫助所有學生在進入大學階段和未來志向做好準備，<br />
                讓孩子成為強大的讀者、自信的作家和批判性思考者。</span
              >
              <div class="justify-content-center justify-content-lg-start">
                <a
                  href="/BookPage/ReadingPage/VistaEngageG9-12"
                  target="_blank"
                  class="btn-more scrollto"
                  >了解更多 ▶
                </a>
              </div>
            </div>
            <div
              class="col-lg-6 order-4 order-lg-2 hero-img"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <img 
                src="../assets/img-1/Banner/04_Banner_small_Engage.png"
                class="img-fluid animated"
                alt="Banner 3"
              />
            </div>
          </div>
        </div>
      </div>

      <button
        class="carousel-control-prev"
        style="top: 50%; left: -140px; opacity: 1"
        type="button"
        data-bs-target="#carouselExampleDark"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        style="top: 50%; right: -140px; opacity: 1"
        type="button"
        data-bs-target="#carouselExampleDark"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</template>

<style>
.T1 {
  font-size: 30px;
  color: #00489b;
  /* font-family: "cwTeXYen", sans-serif; */
  text-align: center;
}
.T2 {
  font-size: 50px;
  line-height: 70px;
  font-weight: 500;
  color: #00489b;
  /* font-family: "cwTeXYen", sans-serif; */
  text-align: center;
}
.T3 {
  font-size: 20px;
  line-height: 35px;
  color: #00489b;
  /* font-family: "cwTeXYen", sans-serif; */
  text-align: center;
}
.btn-more {
  /* font-family: "cwTeXYen", sans-serif; */
  /* font-weight: 100; */
  font-size: 18px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 5px 15px 5px 15px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 25px 0 0 0;
  color: #fff;
  background: #000000;
}

.T-light {
  text-shadow: 0px 0px 3px #fff;
}

a:hover {
  color: #fff;
  text-shadow: 0px 0px 3px #fff,0px 0px 3px #fff,0px 0px 3px #fff,0px 0px 3px #fff;
}
.pb {
  margin:10px 10px;
}

@media (max-width: 540px) {
  .T1 {
  font-size: 20px;
  color: #00489b;
  /* font-family: "cwTeXYen", sans-serif; */
  text-align: center;
}
.T2 {
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  color: #00489b;
  /* font-family: "cwTeXYen", sans-serif; */
  text-align: center;
}
.T3 {
  font-size: 15px;
  line-height: 30px;
  color: #00489b;
  /* font-family: "cwTeXYen", sans-serif; */
  text-align: center;
}
}
</style>
