<template>
  <div>
    <!-- Bootstrap Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content" style="background-color: rgba(255, 255, 255, 0);border: 0;">
          <div class="modal-header" style="border-bottom:0">
            <!-- <h5 class="modal-title" id="exampleModalLabel">聯洋文化有新夥伴了!!</h5> -->
            <button
              type="button"
              class="btn-close btn-close-white"
              @click="closeModal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <!-- 這裡放置圖片 -->
             <a href="https://www.union.com.tw/VISTA-onepage_zh/UnionOceanXVista.html">
            <img
              src="@/assets/img-1/PopUpAd.png"
              class="img-fluid"
              alt="示例圖片"
            />
          </a>
          </div>
          <!-- <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal">
              關閉
            </button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { onMounted, ref } from "vue";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js"; // 確保引入的是 bundle

export default {
  setup() {
    let myModal = ref(null);
    let showList = ['/'];

    console.log('RRRRRRR', window.location)
    const showModal = () => {
      if (myModal.value) {
        myModal.value.show();
      }
    };

    const closeModal = () => {
      if (myModal.value) {
        myModal.value.hide();
      }
    };

    onMounted(() => {
      // 初始化 Modal 並自動顯示
      const modalElement = document.getElementById("exampleModal");
      myModal.value = new bootstrap.Modal(modalElement);
      showModal(); // 頁面加載後自動顯示 Modal
    });

    return {
      showModal,
      closeModal,
      showList,
    };
  },
};
</script>

